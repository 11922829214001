import React from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import "../index.css";

const App = () => {
  const { unityProvider, isLoaded, loadingProgression } = useUnityContext({
    loaderUrl: "Web.loader.js",
    dataUrl: "Web.data.unityweb",
    frameworkUrl: "Web.framework.js.unityweb",
    codeUrl: "Web.wasm.unityweb",
  });
  const loadingPercentage = Math.round(loadingProgression * 100);

  // isLoaded = false;

  return (
    <div id="game-container">
      {
        isLoaded === false && (
        <div id="loading-overlay">
          <div id="progress-container">
            <p id="progress-text">Loading... ({loadingPercentage}%)</p>
            <div id="progress-bar" style={{ width: `${loadingPercentage}%`, backgroundColor: "turquoise" }} />
          </div>
        </div>
      )}
      <Unity unityProvider={unityProvider} />
    </div>
  );
}

export default App;